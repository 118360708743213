<div class="filter-loading">
  <mat-form-field appearance="outline" floatLabel="always" id="searchFormField">
    <mat-label>Suche</mat-label>
    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Sie können alles suchen" />
  </mat-form-field>
  <button *ngIf="data" mat-raised-button color="primary" type="submit" (click)="exportUsers()">
    <span appTranslationModal>Export users</span>
  </button>
</div>

<table *ngIf="!loading" mat-table [dataSource]="dataSource" matSort class="full-width">
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vor und Nachname</th>
    <td mat-cell *matCellDef="let user">
      <div class="d-flex">
        <span> {{ user.name + ' ' + user.lastname }}</span>
      </div>
    </td>
  </ng-container>

  <!-- Firmenname Column -->
  <ng-container matColumnDef="Firmenname">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Firmenname</th>
    <td mat-cell *matCellDef="let user">
      <div class="d-flex">
        <span> {{ user.natPersonen[findFlaggedNatPerson(user.natPersonen)]?.Firmenname }}</span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="customInfo">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Custom Info</th>
    <td mat-cell *matCellDef="let user">
      <mat-form-field appearance="fill" class="resizable">
        <textarea
          matInput
          [(ngModel)]="user.customInfo"
          placeholder="Custom Info"
          rows="2"
          cols="50"
          [disabled]="disableTheCustomerInfo()"
          (change)="saveCustomInfo(user._id, user.customInfo)"
        ></textarea>
      </mat-form-field>
    </td>
  </ng-container>

  <!-- Bank Column -->
  <ng-container matColumnDef="bank">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Bank</th>
    <td mat-cell *matCellDef="let user">{{ user.lang }}</td>
  </ng-container>

  <!-- Status Column -->

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let user">
      <span appTranslationModal [translate]="user.status"></span>
    </td>
  </ng-container>

  <!--RechtsformAuspraegung Column-->

  <ng-container matColumnDef="RechtsformAuspraegung">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>RechtsformAuspraegung</th>
    <td mat-cell *matCellDef="let user">
      <span>{{ user?.RechtsformAuspraegung }}</span>
    </td>
  </ng-container>

  <!-- SubStatus Column -->

  <ng-container matColumnDef="subStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>SubStatus</th>
    <td mat-cell *matCellDef="let user">
      <span appTranslationModal [translate]="user.subStatus"></span>
      <br />
      <span appTranslationModal [translate]="user.finapiStatus"></span>
    </td>
  </ng-container>

  <!-- Role Column -->
  <ng-container matColumnDef="rol">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
    <td mat-cell *matCellDef="let user">
      <mat-form-field floatLabel="always" class="mr-2" style="max-width: 8.5rem">
        <mat-select [(ngModel)]="user.rol" name="role" (selectionChange)="saveRole(user)" [disabled]="disableTheRole()">
          <mat-option *ngFor="let role of roleList" [value]="role.key">
            {{ role.name | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
  </ng-container>

  <!--CreateAt Column-->

  <ng-container matColumnDef="createAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag des Imports</th>
    <td mat-cell *matCellDef="let user">
      <span>{{ user.createAt | date }}</span>
    </td>
  </ng-container>

  <!--LastLogin Column-->

  <ng-container matColumnDef="lastLogin">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Letzte Login</th>
    <td mat-cell *matCellDef="let user">
      <span>{{ user.lastLogin | date }}</span>
    </td>
  </ng-container>

  <!--Products Column-->

  <ng-container matColumnDef="products">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Producte</th>
    <td mat-cell *matCellDef="let user">
      <div class="same-line">
        <button
          class="editBtn"
          [class.button-true]="getValueBasedOnStatus(user, 'kontokorrentkredit')"
          [matTooltip]="'Kontokorrent'"
        ></button>
        <label>Kontokorrent</label>
      </div>
      <div class="same-line">
        <button
          class="editBtn"
          [class.button-true]="getValueBasedOnStatus(user, 'kreditKarte')"
          [matTooltip]="'Kreditkarte'"
        ></button>
        <label>Kreditkarte</label>
      </div>
      <div class="same-line">
        <button
          class="editBtn"
          [class.button-true]="getValueBasedOnStatus(user, 'kontoWechselService')"
          [matTooltip]="'Kontowechsel'"
        ></button>
        <label>Kontowechsel</label>
      </div>
      <div class="same-line">
        <button
          class="editBtn"
          [class.button-true]="getValueBasedOnStatus(user, 'abtretungsvertrag')"
          [matTooltip]="'abtretungsvertrag'"
        ></button>
        <label>Abtretungsvertrag</label>
      </div>
    </td>
  </ng-container>

  <!--Creditcheck Column-->

  <ng-container matColumnDef="creditCheck">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Creditcheck</th>
    <td mat-cell *matCellDef="let user">
      <div class="same-line">
        <button
          class="editBtn"
          [ngClass]="{
            creditCheckRedBtn:
              user?.questions[0]?.answer.kontokorrentkredit === true &&
              user.finapi?.creditCheck?.decisionCredit === 'RED',
            creditCheckGreenBtn:
              user?.questions[0]?.answer.kontokorrentkredit === true &&
              user.finapi?.creditCheck?.decisionCredit === 'GREEN',
          }"
          [matTooltip]="'Creditcheck'"
          (click)="viewCreditCheckDetails(user)"
        >
          <mat-icon *ngIf="user.finapi?.creditCheck?.decisionCredit === 'GREEN'">check</mat-icon>
          <mat-icon *ngIf="user.finapi?.creditCheck?.decisionCredit === 'RED'">close</mat-icon>
          <mat-icon
            *ngIf="
              user.finapi?.creditCheck?.decisionCredit !== 'RED' && user.finapi?.creditCheck?.decisionCredit !== 'GREEN'
            "
          >
            sync
          </mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="daysInSys">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tage seit Import</th>
    <td mat-cell *matCellDef="let user">
      <span>{{ calculateDiff(user.createAt) || 0 }}</span>
    </td>
  </ng-container>

  <!-- butons Column -->
  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef>Edit</th>
    <td mat-cell *matCellDef="let user">
      <button
        class="editBtn"
        id="deleteBtn"
        [matTooltip]="'deleteUser'"
        *ngIf="currentUser.rol === role.admin && !isProd"
        (click)="deleteUser(user._id)"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <button
        class="editBtn"
        id="lockOpenBtn"
        [matTooltip]="'unblockUser'"
        *ngIf="user.blockedUser"
        (click)="unblockUser(user._id)"
      >
        <mat-icon>lock_open</mat-icon>
      </button>
      <button
        class="editBtn"
        id="restoreBtn"
        [matTooltip]="'resetUser'"
        [attr.data-cy]="'resetUser-' + user.email"
        *ngIf="currentUser.rol === role.admin && !isProd"
        (click)="resetUser(user._id)"
      >
        <mat-icon>restore</mat-icon>
      </button>
      <button
        class="editBtn"
        id="historyBtn"
        [matTooltip]="'userHistory'"
        *ngIf="currentUser.rol === role.admin && !isProd"
        (click)="userHistoryClicked(user._id)"
      >
        <mat-icon>History</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 25, 50, 100]"> </mat-paginator>
<div class="filter-loading">
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>
